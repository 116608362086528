import { useEffect, useState } from "react";
import {
  useGetAllSurveyResponseListsQuery,
  useGetSurveyDetailsQuery,
} from "../../api/reportsApi";
import { useReactToPrint } from "react-to-print";

export default function useReports({ surveyId }) {
  const {
    data: surveyResponseList,
    isSuccess: isSurveyResponseListSuccess,
    isFetching: isSurveyResponseListFetching,
    refetch: surveyResponseListRefetch,
  } = useGetAllSurveyResponseListsQuery(
    { id: surveyId },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: surveyDetailsList,
    isSuccess: isSurveyDetailsListSuccess,
    isFetching: isSurveyDetailsListFetching,
    refetch: surveyDetailsListRefetch,
  } = useGetSurveyDetailsQuery(
    { id: surveyId },
    { refetchOnMountOrArgChange: true }
  );

  const [surveyResponseListData, setSurveyResponseListData] = useState([]);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const generatePDF = useReactToPrint({
    documentTitle: "Summary Report",
    onBeforePrint: () => setPdfDownloading(true),
    onAfterPrint: () => setPdfDownloading(false),
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (
      isSurveyResponseListSuccess &&
      !isSurveyResponseListFetching &&
      isSurveyDetailsListSuccess &&
      !isSurveyResponseListFetching &&
      surveyId
    ) {
      const data =
        surveyResponseList?.flatMap((item) => {
          const responseId = item?.response_id;
          const surveyname = item?.response_data?.surveyname;
          const state = item?.response_data?.statename;
          const district = item?.response_data?.districtname;
          const constituency = item?.response_data?.constituencyname;
          const village = item?.response_data?.village;
          const age = item?.response_data?.age;
          const caste = item?.response_data?.caste;
          const recording = item?.response_data?.voice || "null";
          const additionDetail = surveyDetailsList?.data?.filter(
            (detail) => detail?.response_id === responseId
          );

          return item.response_questions.map((question) => {
            const questionText = question?.question_text;
            const answers = item.response_answers
              .filter((answer) => answer?.question_id === question?.id)
              .map((answer) => answer?.answer_text);
            return {
              response_id: responseId,
              surveyname: surveyname,
              username: additionDetail?.[0]?.username || "null",
              state: state,
              district: district,
              constituency: constituency,
              village: village,
              age: age,
              caste: caste,
              question: questionText,
              answers: answers,
              recording: recording,
              latitude: additionDetail?.[0]?.latitude || "null",
              longitude: additionDetail?.[0]?.longitude || "null",
              logTimestamp: additionDetail?.[0]?.time_log || "null",
            };
          });
        }) || [];
      setSurveyResponseListData(data);
    }
  }, [isSurveyResponseListFetching, isSurveyResponseListSuccess]);

  return {
    surveyResponseListData,
    isSurveyResponseSuccess:
      !isSurveyResponseListSuccess && isSurveyResponseListFetching,
    surveyResponseListRefetch: surveyResponseListRefetch,
    generatePDF,
    pdfDownloading,
  };
}
